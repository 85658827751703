import { environment } from '../environments/environment';

export const LANG_EN = 'en';
export const LANG_DE = 'de';

export const languages = [
    {
        key: LANG_EN,
        value: 'English'
    },
    {
        key: LANG_DE,
        value: 'Deutsch'
    }
];

export const GENDERS = [
    {value: 'MALE', label: 'common.gender.male'},
    {value: 'FEMALE', label: 'common.gender.female'},
    {value: 'OTHER', label: 'common.gender.other'}
];

export const MIN_AGE_OPTIN = 18;
export const MAX_AGE_OPTIN = 80;

export const MAX_TASKS_TO_REMINDER_CLOSE_TASK = 8;

export const DEFAULT_LANGUAGE = 'de';

export const CONTACT_INFO = {
    imprintPhone: '040 752 901 600',
    phone: '040 752 901 600',
    email: 'kontakt@finstein.de',
    fax: '040 752 901 603'
};

/**
 * System permissions
 */
export const PERM_FINSTEIN_ROLE_ADD = 'finstein-role-add';
export const PERM_FINSTEIN_ROLE_READ = 'finstein-role-read';
export const PERM_FINSTEIN_USER_ADD = 'finstein-user-add';
export const PERM_FINSTEIN_USER_READ = 'finstein-user-read';

/**
 * Local Storage keys
 */
export const ALLOW_COOKIES_LOCAL_STORAGE_KEY = 'allowCookies';
export const LANG_KEY_LOCAL_STORAGE_KEY = 'langKey';
export const FCM_LOCAL_STORAGE_KEY = 'fcm';

/**
 * Firebase collections
 */
export const USERS_COLLECTION = 'users';
export const APPROVALS_FEATURES_COLLECTION = 'approvals-features';
export const APPROVALS_REQUESTS_COLLECTION = 'approvals-request';
export const IMPORT_SETTINGS_COLLECTION = 'import-settings';
export const APPROVALS_CONFIG_COLLECTION = 'approvals-configuration';
export const FCM_DEVICES_COLLECTION = 'fcm-devices';
export const NOTIFICATIONS_COLLECTION = 'notifications';
export const NOTIFICATION_TOPICS_COLLECTION = 'notification-topics';
export const COMPANIES_COLLECTION = 'companies';
export const COMPANIES_CONTRACT_DATA_COLLECTION = 'companies-contract-data';
export const CONTRACTS_COLLECTION = 'contracts';
export const COMPANY_TAX_INFORMATION = 'tax-information';
export const COMPANY_LEADS_COLLECTION = 'company-leads';
export const PARTNER_COMPANIES_COLLECTION = 'partner-companies';
export const PARTNER_COMPANIES_GIFT_CARDS_COLLECTION = 'gift-cards';
export const PARTNER_COMPANIES_CARDS_COLLECTION = 'cards';
export const PARTNER_COMPANIES_REPORTS_COLLECTION = 'reports';
export const ROLES_COLLECTION = 'roles';
export const PERMISSIONS_COLLECTION = 'permissions';
export const ADDRESSES_COLLECTION = 'addresses';
export const CANTEENS_COLLECTION = 'canteens';
export const USERS_CLAIMS_COLLECTION = 'users-claims';
export const BENEFITS_SERVICES_COLLECTION = 'benefits-services';
export const BOOKKEEPING_KEYS_COLLECTION = 'bookkeeping-keys';
export const SERVICES_COLLECTION = 'services';
export const TASKS_COLLECTION = 'tasks';
export const AREAS_COLLECTION = 'areas';
export const PAYROLL_COLLECTION = 'payroll';
export const APPROVALS_HISTORY_COLLECTION = 'approvals-history';
export const EMPLOYEES_COLLECTION = 'employees';
export const DOCUMENTS_COLLECTION = 'documents';
export const OPTIN_IMPORTED_COLLECTION = 'optin-imported-data';
export const QUESTIONS_COLLECTION = 'questions';
export const PRODUCT_ORDERS_COLLECTION = 'product-orders';
export const PRODUCT_COLLECTION = 'products';
export const HARDWARE_COLLECTION = 'hardware';
export const ADVANCED_COLLECTION = 'advanced-data';
export const CONSULTANCY_PROCESS_COLLECTION = 'consultancy-process';
export const SUPPORT_REQUESTS_COLLECTION = 'support-requests';
export const COLLECTIVE_AGREEMENT_COLLECTION = 'collective-agreement';
export const DEADLINE_FOR_BOOKING_SUGGESTIONS_COLLECTION = 'deadline-for-booking-suggestions';
export const DIFFERENT_MINIMUM_WAGE_COLLECTION = 'different-minimum-wage';
export const TRADE_ASSOCIATION_COLLECTION = 'trade-association';
export const WAGE_ACCOUNTING_SYSTEM_COLLECTION = 'wage-accounting-system';
export const EMPLOYEE_MASTER_DATA_COLLECTION = 'master-data';
export const BOOKKEEPING_SUGGESTIONS_COLLECTION = 'bookkeeping-suggestions';
export const EMPLOYEE_ADVANCED_DATA_COLLECTION = 'advanced-data';
export const SCHEDULED_TASKS_COLLECTION = 'scheduled-tasks';
export const PRODUCTS_BASIC_INFO_COLLECTION = 'products-basic-info';
export const PRODUCTS_IMAGES_COLLECTION = 'products-images';
export const EMAILS_LEADS_COLLECTION = 'emails-leads';
export const DOWNLOAD_CENTER_COLLECTION = 'download-center';
export const EMAILS_TEMPLATE_COLLECTION = 'emails-template';
export const VIDEO_GALLERY_COLLECTION = 'video-gallery';
export const APPROVE_CONTRACTS_TASKS_COLLECTION = 'approve-contracts-tasks';
export const CELL_PHONE_PLANS_COLLECTION = 'cell-phone-plans';
export const GENERAL_COLLECTION = 'general';
export const CHANGES_HISTORY_COLLECTION = 'changes-history';
export const CUSTOM_NOTIFICATIONS_COLLECTION = 'custom-notifications';

/**
 * Advanced employee data
 */
export const ADDRESS_COLLECTION = 'address';
export const ABSENCE_REGISTRATION_COLLECTION = 'absence-registration';
export const COLLECTIVE_WAGE_COLLECTION = 'collective-wage';
export const EXTRA_TARIFF_ALLOWANCES_COLLECTION = 'extra-tariff-allowances';
export const GROSS_SALARY_COLLECTION = 'gross-salary';
export const JOB_ADDRESS_COLLECTION = 'job-address';
export const SPECIAL_PAYMENTS_COLLECTION = 'special-payments';
export const WEEKLY_WORKING_HOURS_COLLECTION = 'weekly-working-hours';
export const WORK_WITH_CASH_REGISTER_COLLECTION = 'work-with-cash-register';

export const COLLECTION_GLOBAL_VARIABLES = 'global-variables';

/**
 * Global varibles keys
 */
export const GV_KEY_COMPOSITE_VARIABLES = 'variables';
export const GV_KEY_ANNUAL_EARNINGS = 'annual-earnings';
export const GV_ADMIN_TRUST_LEVEL_SCORE = 'admin-trust-level-score';
export const GV_KEY_TAXES = 'taxes';
export const GV_KEY_WAGE_ACCOUNTING_SYSTEM = 'wage-accounting-system';
export const GV_KEY_PORTAL_TITLE_VIDEOS = 'portal-title-videos';
export const GV_KEY_FAQ_CONSTRAINTS = 'faq-constraints';
export const GV_EMAIL_LEADS_CONSTRAINTS = 'email-leads';
export const GV_EMAIL_TEMPLATE_SETTINGS = 'email-template-settings';

/**
 * Firebase functions
 */
export const CREATE_USER_FUNCTION = 'createUser';
export const UPDATE_USER_FUNCTION = 'updateUser';
export const USER_CHANGE_PASSWORD = 'userChangePassword';
export const CREATE_COMPANY_FUNCTION = 'createCompany';
export const CREATE_COMPANY_CANTEEN_FUNCTION = 'createCompanyCanteen';
export const UPDATE_COMPANY_CANTEEN_FUNCTION = 'updateCompanyCanteen';
export const COMPANY_RESPONSIBLE_VALIDATOR = 'companyResponsibleValidator';
export const UPDATE_COMPANY_FUNCTION = 'updateCompany';
export const SUBSCRIBE_TO_TOPIC_FUNCTION = 'subscribeToTopic';
export const UNSUBSCRIBE_FROM_TOPIC_FUNCTION = 'unsubscribeFromTopic';
export const REQUEST_RESET_PASSWORD_FUNCTION = 'requestResetPassword';
export const GET_USER_BY_RESET_PASSWORD_KEY = 'getUserByResetPasswordKey';
export const CHANGE_PASSWORD_FUNCTION = 'changePassword';
export const CREATE_APPROVAL_CONFIG_FUNCTION = 'createApprovalConfig';
export const UPDATE_APPROVAL_CONFIG_FUNCTION = 'updateApprovalConfig';
export const CREATE_APPROVAL_HISTORY_FUNCTION = 'createApprovalsHistory';
export const REMOVE_COMPANY_LEAD_FUNCTION = 'removeCompanyLead';
export const REMOVE_USER_FUNCTION = 'removeUser';
export const CREATE_ROLE_FUNCTION = 'createRole';
export const UPDATE_ROLE_FUNCTION = 'updateRole';
export const REMOVE_ROLE_FUNCTION = 'removeRole';
export const CREATE_COMPANY_UNIT_FUNCTION = 'createCompanyUnit';
export const UPDATE_COMPANY_UNIT_FUNCTION = 'updateCompanyUnit';
export const GENERATE_OPTIN_INVITE_LINK_FUNCTION = 'generateOptinInviteLink';
export const CREATE_REQUEST_OPTIN_FUNCTION = 'createRequestOptin';
export const IMPORT_EMPLOYEE_DATA_FUNCTION = 'importEmployeeData';
export const REPROCESS_TASKS_FOR_ALL_EMPLOYEES_FUNCTION = 'reprocessTasksForAllEmployees';
export const INVITE_COMPANY_LEAD_FUNCTION = 'inviteCompanyLead';
export const REPROCESS_OPTIN_DATA_FUNCTION = 'reprocessOptinData';
export const SEND_EMPLOYEE_TO_CONSULTANCY_FUNCTION = 'sendEmployeeToConsultancy';
export const UPDATE_OPTIN_PERSONAL_DATA_FUNCTION = 'updateOptinPersonalData';
export const GET_COP_CONTRACT_BY_OPTIN_ID_OPTIN_FUNCTION = 'getCopContractByOptinId';
export const APPROVE_OPTIN_FUNCTION = 'approveEmployee';
export const REJECT_OPTIN_FUNCTION = 'rejectEmployee';
export const APPROVE_COMPANY_LEAD = 'approveCompanyLead';
export const REJECT_COMPANY_LEAD = 'rejectCompanyLead';
export const UPDATE_COMPANY_TRUST_FUNCTION = 'updateCompanyTrustLevelScore';
export const TRANSFORM_LEAD_TO_COMPANY_FUNCTION = 'transformLeadToCompany';
export const UPDATE_COMPANY_SERVICES_FUNCTION = 'updateCompanyServices';
export const UPDATE_BOOKKEEPING_KEY_FUNCTION = 'updateBookkeepingKey';
export const UPDATE_ACTION_SEQUENCE_FUNCTION = 'updateActionSequence';
export const UPDATE_READ_RIGHTS_FUNCTION = 'updateReadRights';
export const APPROVE_REQUESTS_FOR_COMPANY_MASTER_DATA_FUNCTION = 'approveRequestsForCompanyMasterData';
export const APPROVE_REQUESTS_FOR_BOOKKEEPING_KEYS_FUNCTION = 'approveRequestsForBookkeepingKeys';
export const UPDATE_COMPANY_CONTRACT_DATA_FUNCTION = 'updateCompanyContractData';
export const APPROVE_REQUESTS_FOR_COMPANY_UNITS_FUNCTION = 'approveRequestsForCompanyUnit';
export const APPROVE_REQUESTS_FOR_EMPLOYEE_ADVANCED_DATA_FUNCTION = 'approveRequestsForEmployeeAdvancedData';
export const APPROVE_REQUESTS_FOR_SALARY_INCREASE_DATA_FUNCTION = 'approveRequestsForSalaryIncreaseData';
export const APPROVE_REQUESTS_FOR_PAYROLL_ACCOUNTING_FUNCTION = 'approveRequestsForPayrollAccounting';
export const CHANGE_STATUS_COMPANY_UNITS_FUNCTION = 'changeStatusCompanyUnit';
export const CHANGE_STATUS_COMPANY_CANTEEN_FUNCTION = 'changeStatusCompanyCanteen';
export const UPDATE_ADVANCED_DATA_EMPLOYEE = 'updateAdvancedDataEmployee';
export const UPDATE_PAYROLL_BOOKKEEPING_FUNCTION = 'updatePayrollBookkeeping';
export const UPDATE_STATUS_DESCRIPTION_OPTIN_FUNCTION = 'updateStatusDescriptionOptin';
export const REMOVE_OPTIN_FUNCTION = 'removeOptin';
export const RESET_EMPLOYEE_COP = 'resetEmployeeCop';
export const GET_USER_PERMISSIONS_COMPANY_FUNCTION = 'getUserPermissionsCompany';
export const SET_SERVICE_DATA_FUNCTION = 'setServiceData';
export const SET_BOOKKEEPING_KEY_FUNCTION = 'setBookkeepingKeys';
export const SET_BOOKKEEPING_KEY_CARD_FUNCTION = 'setBookkeepingKeysCards';
export const UPDATE_INDEX_SERVICE_DATA_FUNCTION = 'updateIndexServiceData';
export const UPDATE_STATUS_SERVICE_DATA_FUNCTION = 'updateStatusServiceData';
export const UPDATE_STATUS_BOOKKEEPING_KEY_DATA_FUNCTION = 'updateBookkepingKeyStatusData';
export const CREATE_COLLECTIVE_AGREEMENT = 'createCollectiveAgreement';
export const CREATE_DEADLINE_FOR_BOOKING_SUGGESTIONS = 'createDeadlineForBookingSuggestions';
export const CREATE_DIFFERENT_MINIMUM_WAGE = 'createDifferentMinimumWage';
export const CREATE_TRADE_ASSOCIATION = 'createTradeAssociation';
export const CREATE_WAGE_ACCOUNTING_SYSTEM = 'createWageAccountingSystem';
export const CANCEL_PAYROLL_SCHEDULE = 'cancelPayrollSchedule';
export const RESET_IMPORT_OPTIN_CONFIG_FUNCTION = 'resetImportOptinConfig';
export const RESEND_EMPLOYEE_COP_CONTRACT = 'resendEmployeeCOPContract';
export const MARK_COP_EMPLOYEE_CONTRACT_AS_SIGNED = 'markCopEmployeeContractAsSigned';
export const RESET_AND_RESEND_EMPLOYEE_TO_COP_FUNCTION = 'resetAndResendEmployeeToCop';
export const RESET_EMPLOYEE_FORMS_FUNCTION = 'resetEmployeeForms';
export const CREATE_GLOBAL_VARIABLES = 'createGlobalVariables';
export const UPLOAD_PRODUCTS_IMAGES_FUNCTION = 'uploadProductsImages';
export const DELETE_PRODUCTS_IMAGES_FUNCTION = 'deleteProductsImages';
export const SEND_INVITE_LEAD_EMAIL_FUNCTION = 'sendInviteLeadEmail';
export const CREATE_PARTNER_COMPANY_FUNCTION = 'createPartnerCompany';
export const UPDATE_PARTNER_COMPANY_FUNCTION = 'updatePartnerCompany';
export const DELETE_PARTNER_COMPANY_FUNCTION = 'deletePartnerCompany';
export const UPDATE_PARTNER_COMPANY_REPORT_FUNCTION = 'updatePartnerCompanyReport';
export const INCREASE_EMPLOYEES_SALARY_FUNCTION = 'increaseEmployeesSalary';
export const SEND_TO_DATA_EXPORT_FOR_CONSULTANCY_PROCESS_FUNCTION = 'sendEmployeeToDataExportForNewConsultancyProcess';
export const SEND_EMPLOYEES_TO_DATA_EXPORT = 'sendEmployeesToDataExport';
export const SET_DEFAULT_PARTNER_COMPANY_FUNCTION = 'setDefaultPartnerCompany';
export const FEEDBACK_CONTRACT_FUNCTION = 'feedbackContract';
export const REGENERATE_CONTRACT_FUNCTION = 'regenerateContract';
export const UPDATE_FINSTEIN_HELPER_FUNCTION = 'updateFinsteinHelper';
export const REMOVE_FINSTEIN_HELPER_COMPANY_DATA_FUNCTION = 'removeFinsteinHelperCompanyData';
export const CREATE_CELL_PHONE_PLAN_FUNCTION = 'createCellPhonePlan';
export const UPDATE_CELL_PHONE_PLAN_FUNCTION = 'updateCellPhonePlan';
export const REMOVE_CELL_PHONE_PLAN_FUNCTION = 'removeCellPhonePlan';
export const UPDATE_ERROR_REPORT_OPTIN_FUNCION = 'updateErrorReportOptin';
export const CONFIRM_CONTRACT_INSERTED_INTO_PAYROLL_SYSTEM_FUNCTION = 'confirmContractInsertedIntoPayrollSystem';
export const UPDATE_SUPPORT_REQUESTS_FUNCTION = 'updateSupportRequest';
export const FEEDBACK_DOWNLOAD_CENTER = 'feedbackDownloadCenter';
export const CREATE_ADVANCED_EMPLOYEE_DATA_ADDRESS = 'createAdvancedEmployeeDataAddress';
export const CREATE_ADVANCED_EMPLOYEE_DATA_ABSENCE_REGISTRATION = 'createAdvancedEmployeeDataAbsenceRegistration';
export const CREATE_ADVANCED_EMPLOYEE_DATA_COLLECTIVE_WAGE = 'createAdvancedEmployeeDataCollectiveWage';
export const CREATE_ADVANCED_EMPLOYEE_DATA_EXTRA_TARIFF_ALLOWANCES = 'createAdvancedEmployeeDataExtraTariffAllowances';
export const CREATE_ADVANCED_EMPLOYEE_DATA_GROSS_SALARY = 'createAdvancedEmployeeDataGrossSalary';
export const CREATE_ADVANCED_EMPLOYEE_DATA_JOB_ADDRESS = 'createAdvancedEmployeeDataJobAddress';
export const CREATE_ADVANCED_EMPLOYEE_DATA_SPECIAL_PAYMENTS = 'createAdvancedEmployeeDataSpecialPayments';
export const CREATE_ADVANCED_EMPLOYEE_DATA_WEEKLY_WORKING_HOURS = 'createAdvancedEmployeeDataWeeklyWorkingHours';
export const CREATE_ADVANCED_EMPLOYEE_DATA_WORK_WITH_CASH_REGISTER = 'createAdvancedEmployeeDataWorkWithCashRegister';
export const CANCEL_ADVANCED_EMPLOYEE_DATA_SCHEDULE = 'cancelScheduleToAdvancedData';
export const REPROCESS_TASKS_FUNCTION = 'reprocessAllTasksChecks';
export const GENERATE_PROPOSALS_PREVIEW_FUNCTION = 'generateProposalsPreview';
export const GENERATE_SUMMARY_RECEIPTS_FUNCTION = 'generateSummaryReceipts';
export const SCHEDULE_EMPLOYEE_DEACTIVATION_FUNCTION = 'scheduleEmployeeDeactivation';
export const GET_EMPLOYEES_SERVICES_FOR_REPORT_FUNCTION = 'getEmployeesServicesForReport';
export const RECAPTCHA_VALIDATOR_FUNCTION = 'recaptchaValidator';
export const SEND_OPTIN_TO_DATA_EXPORT_FUNCTION = 'sendOptinToDataExport';
export const CREATE_REPORT = 'createReport';
export const CREATE_SMARTPHONE_LIST_FUNCTION = 'importSmartphoneData';
export const SET_SIMPLE_PRODUCT_SMARTPHONE = 'setSimpleProductSmartphone';
export const SYNC_SMARTPHONE_IMAGES = 'syncSmartphoneImages';
export const APPROVE_EMPLOYEE_RECEIPT_FUNCTION = 'approveEmployeeReceipt';
export const REJECT_EMPLOYEE_RECEIPT_FUNCTION = 'rejectEmployeeReceipt';
export const UPDATE_CONTRACT_DATE_FUNCTION = 'updateContractDate';
export const INVALIDATE_CONTRACT_FUNCTION = 'invalidateContract';
export const CREATE_OPTIMIZER_NOTIFICATION_FUNCTION = 'createOptimizerNotification';
export const UPDATE_OPTIMIZER_NOTIFICATION_FUNCTION = 'updateOptimizerNotification';
export const SEND_OPTIMIZER_CUSTOM_NOTIFICATION_FUNCTION = 'sendOptimizerCustomNotification';
export const START_PURCHASING_COUPONS_FUNCTION = 'couponApi-startPurchasingCoupons';
export const GET_CADOOZ_BALANCE_FUNCTION = 'couponApi-getBalance';


/**
 * Permissions and responsibilities
 */
export const PERM_REGISTER_OPTIMIZED_GROSS_SALARY = 'register-optimized-gross-salary';
export const PERM_RELEASE_EMPLOYEE_FOR_SALARY_INCREASE = 'release-employee-for-salary-increase';
export const PERM_MANAGE_ADMIN_FOR_EACH_AREA = 'manage-admin-for-each-area';
export const PERM_MANAGE_GRANTING_VIEWING_RIGHTS = 'manage-granting-viewing-rights';
export const PERM_MANAGE_DOCUMENT_TEMPLATES = 'manage-document-templates';
export const PERM_MANAGE_SERVICES = 'manage-services';
export const PERM_PAYROLL_BOOKKEEPING = 'payroll-bookkeeping';
export const PERM_BOOKKEEPING = 'bookkeeping';
export const PERM_HUMAN_RESOURCE = 'human-resource';
export const PERM_DEFINE_BOOKING_KEY = 'define-booking-key';
export const PERM_RECEIVE_BOOKING_SUGGESTIONS = 'receive-booking-suggestions-and-transfer-them-to-the-payroll-system';
export const PERM_VERIFY_EMPLOYEE_ADVICE_REQUESTS = 'verify-employee-advice-requests';
export const PERM_DELIVER_EMPLOYEE_MASTER_DATA_TO_FINSTEIN = 'deliver-employee-master-data-to-finstein';
export const PERM_EDIT_COMPANY_MASTER_DATA = 'edit-company-master-data';
export const PERM_ACTIVATION_HARDWARE = 'activation-hardware';
export const PERM_REIMBURSEMENT_OF_EXPENSES = 'reimbursement-of-expenses';
export const PERM_SIGN_ADDITIONS_TO_EMPLOYMENT_CONTRACTS = 'sign-additions-to-employment-contracts';
export const PERM_CHANGE_EMPLOYEE_MASTER_DATA = 'change-employee-master-data';
export const PERM_RELEASE_OPTIN_TO_CONSULTANCY = 'release-optin-to-consultancy';
export const PERM_CONFIG_PAYROLL_ACCOUNTING = 'config-payroll-accounting';
export const PERM_VIEW_INVOICES_FROM_FINSTEIN = 'view-invoices-from-finstein';

/**
 * Firebase data
 */
export const FIREBASE_REGION = 'europe-west1';
export const FIREBASE_URL_FUNCTIONS = `https://${ FIREBASE_REGION }-${ environment.firebaseConfig.projectId }.cloudfunctions.net/`;
export const FIREBASE_STORAGE_BUCKET_BASE_URL =
    `https://firebasestorage.googleapis.com/v0/b/${ environment.firebaseConfig.storageBucket }/o/`;
export const PORTAL_VIDEOS_PATH = `${ FIREBASE_STORAGE_BUCKET_BASE_URL }portal-videos%2F`;

/**
 * Firebase query operators
 */
export const OP_ARRAY_CONTAINS = 'array-contains';
export const OP_EQUALS = '==';
export const OP_NOT_EQUALS = '!=';
export const OP_IN = 'in';
export const OP_NOT_IN = 'not-in';
export const OP_GREATER_THAN_OR_EQUAL_TO = '>=';
export const OP_SMALLER_THAN_OR_EQUAL_TO = '<=';

/**
 * Available features that required approvals
 */
export const ACTIONS_APPROVALS_CONFIG = [ 'CREATE', 'REMOVE', 'UPDATE' ];

/**
 * Patterns
 */
export const EMAIL_REGEX = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$';
export const VARIABLE_REGEX = '(?<=[a-z])|(?=[A-Z])';

/**
 * System's status
 */
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_UPLOADED = 'UPLOADED';
export const STATUS_SKIPPED = 'SKIPPED';
export const STATUS_NOT_A_FINSTEIN_USER = 'NOT_A_FINSTEIN_USER';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_REPLACED = 'REPLACED';
export const STATUS_DATA_COMPLETED = 'DATA_COMPLETED';
export const STATUS_NOT_ELIGIBLE_FOR_OPTIMIZATION = 'NOT_ELIGIBLE_FOR_OPTIMIZATION';
export const IMPORTED = 'IMPORTED';
export const STATUS_DATA_MISSING = 'DATA_MISSING';
export const STATUS_ERROR = 'ERROR';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_INACTIVE = 'INACTIVE';
export const STATUS_PARTIALLY_APPROVED = 'PARTIALLY_APPROVED';
export const STATUS_PENDENT = 'PENDENT';
export const STATUS_TODO = 'TO_DO';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_DONE = 'DONE';
export const STATUS_ACTIVE_PAYROLL_ENTRY_PENDING = 'STATUS_ACTIVE_PAYROLL_ENTRY_PENDING';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_SCHEDULED_TO_TEMPORARY_INACTIVATION = 'SCHEDULED_TO_TEMPORARY_INACTIVATION';
export const STATUS_SCHEDULED_TO_PERMANENT_INACTIVATION = 'SCHEDULED_TO_PERMANENT_INACTIVATION';

export const FINSTEIN_CHECK = 'FINSTEIN_CHECK';
export const STATUS_SIGNED = 'SIGNED';
export const STATUS_FINISHED = 'FINISHED';
export const STATUS_CONSULTATION_APPROVAL_PENDING = 'CONSULTATION_APPROVAL_PENDING';
export const STATUS_SALARY_INCREASE_APPROVED_AND_PENDING_CONFIRMATION = 'SALARY_INCREASE_APPROVED_AND_PENDING_CONFIRMATION';
export const STATUS_DATA_EXPORT_PENDING = 'DATA_EXPORT_PENDING';
export const STATUS_DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS = 'DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS';
export const STATUS_CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS = 'CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS';
export const STATUS_INCREASE_APPROVED_AND_PENDING_CONFIRMATION = 'INCREASE_APPROVED_AND_PENDING_CONFIRMATION';
export const STATUS_PENDING_OPTIN = 'PENDING_OPTIN';
export const STATUS_SENT = 'SENT';
export const STATUS_CLICKED = 'CLICKED';
export const STATUS_DISABLED = 'DISABLED';

export const OPTINS_IMPORT_SETTINGS_ID = 'optins-import-settings';

/**
 *  Types of tasks
 */
export const CATEGORY_OPEN_TASKS = 'OPEN_TASKS';

/**
 * Approvals features
 */
export const FEATURE_ROLES = 'ROLES';
export const FEATURE_DEFINE_BOOKING_KEY = 'define-booking-key';


export const SETTINGS_NOTIFICATIONS_TAB_ID = 'notifications-tab';
export const SETTINGS_OPTINS_IMPORTATION_TAB_ID = 'optins-importation-tab';

/**
 * Opt-ins importation
 */
export const ENCODING_READER_CSV_FILE = 'ISO-8859-1';
export const MAX_SIZE_UPLOAD_OPTIN_IMPORTATION = 1000000000; // 1TB
export const FIELD_TYPE_WAGE_KEYS = 'WAGE-KEYS';
export const FIELD_TYPE_SERVICE = 'SERVICE';
export const FIELD_TYPE_NUMBER = 'NUMBER';
export const FIELD_TYPE_BOOLEAN = 'BOOLEAN';
export const FIELD_TYPE_TEXT = 'TEXT';
export const FIELD_TYPE_DATE = 'DATE';

export const ACTION_CREATE = 'CREATE';
export const ACTION_UPDATE = 'UPDATE';
export const ACTION_ENABLE = 'ENABLE';
export const ACTION_DISABLE = 'DISABLE';

/**
 * Pagination
 */
export const ITEMS_PER_PAGE = 20;

/**
 * Allowed states to company
 */
export const STATES = [
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
];

/* Auth params */
export const AUTH_EXPIRATION_TIME = environment.authExpirationTime;
export const AUTH_RECOVERY_TIME = environment.authRecoveryTime;
export const INACTIVE_PING_CHECK = 60;
export const INACTIVE_MODAL_ID = 'inactive-close-modal';

/* Tooltip sizes */
export const TOOLTIP_SMALL_LENGTH = 10;
export const TOOLTIP_MEDIUM_LENGTH = 30;
export const TOOLTIP_LARGE_LENGTH = 40;
export const TOOLTIP_XLARGE_LENGTH = 100;

export const TOOLTIP_SIZE_BY_LENGTH = (text: string) => {
    const length = text?.length || 0;
    if (length <= TOOLTIP_SMALL_LENGTH) {
        return 'small';
    } else if (length <= TOOLTIP_MEDIUM_LENGTH) {
        return 'medium';
    } else if (length <= TOOLTIP_LARGE_LENGTH) {
        return 'large';
    } else {
        return 'xlarge';
    }
};

/* BOOKKEEPING KEYS CARDS */
export const WAGE_TYPES_CARD = 'wage-types';
export const WAGE_TAX_FREE_TYPES_CARD = 'wage-tax-free-types';
export const OTHER_SERVICES_CARD = 'other-services';
export const SERVICES_CARD = 'services';
export const BOOKING_KEY_FOR_COLLECTIVE_AGREEMENTS_CARD = 'booking-key-for-collective-agreements';

export const BOOKKEEPING_KEYS_CARDS = [
    WAGE_TYPES_CARD,
    OTHER_SERVICES_CARD,
    SERVICES_CARD,
    BOOKING_KEY_FOR_COLLECTIVE_AGREEMENTS_CARD,
];
/**
 * Response code error constants
 */
export const RESP_CODE_OK = 'ok';
export const RESP_CODE_UNAUTHORIZED = 'unauthenticated';
export const RESP_CODE_UNKNOWN = 'unknown';
export const RESP_CODE_INVALID_ARGUMENT = 'invalid-argument';
export const RESP_CODE_NOT_FOUND = 'not-found';
export const RESP_CODE_ALREADY_EXISTS = 'already-exists';
export const RESP_CODE_INTERNAL = 'internal';
export const RESP_CODE_CANCELLED = 'cancelled';
export const RESP_CODE_DEADLINE_EXCEEDED = 'deadline-exceeded';
export const RESP_CODE_PERMISSION_DENIED = 'permission-denied';
export const RESP_CODE_RESOURCE_EXHAUSTED = 'resource-exhausted';
export const RESP_CODE_FAILED_PRECONDITION = 'failed-precondition';
export const RESP_CODE_ABORTED = 'aborted';
export const RESP_CODE_OUT_OF_RANGE = 'out-of-range';
export const RESP_CODE_UNIMPLEMENTED = 'unimplemented';
export const RESP_CODE_UNAVAILABLE = 'unavailable';
export const RESP_CODE_DATA_LOSS = 'data-loss';

/**
 * Storage Folders
 */
export const COP_STORE_FOLDER = 'cop-store';

/**
 * Optins percentage status
 */
export const OPTIN_PERCENTAGE_STATUS = {
    welcomeVideoWatched: {
        weight: 20,
        tooltipMessage: 'employees.progress-status.welcome-video-watched',
    },
    distributeNetBenefitWelcomeVideoWatched: {
        weight: 30,
        tooltipMessage: 'employees.progress-status.net-benefit-welcome-video-watched',
    },
    consultancyProcessFinished: {
        weight: 50,
        tooltipMessage: 'employees.progress-status.consultancy-process-finished',
    },
};

/**
 * Image file status
 */
export const IMAGE_UPLOADED_STATUS = 'UPLOADED';

/*
 * Status
 */
export const STATUS_LABELS = {
    ACTIVE: 'status.active',
    INACTIVE: 'status.inactive',
    REJECTED: 'common.rejected',
    PARTIALLY_APPROVED: 'status.partially-approved',
    DATA_MISSING: 'status.data-missing',
    PENDENT: 'status.pendent',
    APPROVED: 'status.approved',
    CONTRACT_EXPIRED: 'status.contract-expired',
    CONTRACT_CANCELED: 'status.contract-canceled',
    VERIFICATION_PENDING: 'status.verification-pending',
    DATA_EXPORT_PENDING: 'status.data-export-pending',
    CONSULTATION_APPROVAL_PENDING: 'status.consultation-approval-pending',
    CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS:
        'status.consultation-approval-pending-for-new-consultancy-process',
    READY_FOR_CONSULTATION_PROCESS: 'status.ready-for-consultation-process',
    IN_THE_CONSULTING_PROCESS: 'status.in-the-consulting-process',
    DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS: 'status.data-export-pending-for-new-consultancy-process',
    COP_COMPLETED_CONTRACT_SIGNING_PENDING:
        'status.cop-completed-contract-signing-pending',
    WAITING_CONTRACT_SIGNATURE: 'status.waiting-contract-signature',
    WAITING_APPROVAL: 'status.waiting-approval',
    WAITING_ACCEPT_PRIVACY_TERMS: 'status.waiting-accept-privacy-terms',
    SALARY_INCREASE_PENDING: 'status.salary-increase-pending',
    DATA_EXPORT_INCOMPLETE: 'status.data-export-incomplete',
    NOT_ELIGIBLE_FOR_OPTIMIZATION: 'status.not-eligible-for-optimization',
    WAITING_FOR_THE_GENERATION_OF_THE_CONTRACT:
        'status.waiting-for-the-generation-of-the-contract',
    PROBLEM_CONTRACT_GENERATION: 'status.problem-contract-generation',
    ACTIVE_FINSTEIN_USER: 'status.active-finstein-user',
    TO_DO: 'status.to-do',
    NOT_A_FINSTEIN_USER: 'status.not-a-finstein-user',
    INCREASE_APPROVED_AND_PENDING_CONFIRMATION:
        'status.increase-approved-and-pending-confirmation',
    PENDING_OPTIN: 'status.pending-optin',
    SENT: 'status.sent',
    TEMPORARY_FAILURE: 'status.temporary-failure',
    RETRY: 'status.retry',
    DELIVERED: 'status.delivered',
    PERMANENT_FAILURE: 'status.permanent-failure',
    OPENED: 'status.opened',
    CLICKED: 'status.clicked',
    SALARY_INCREASE_APPROVED_AND_PENDING_CONFIRMATION:
        'status.salary-increase-approved-and-pending-confirmation',
    FINISHED: 'status.finished',
    SIGNED: 'status.signed-at',
    EMPLOYEE_AND_EMAIL_KNOW: 'optins.status-options.employee-and-email-know',
    EMPLOYEE_KNOW_EMAIL_UNKNOW: 'optins.status-options.employee-know-email-unknow',
    EMPLOYEE_KNOW_NOT_MADE_REQUEST: 'optins.status-options.employee-know-not-made-request',
    EMPLOYEE_UNKNOW: 'optins.status-options.employee-unknow',
    UPLOADED: 'status.waiting-approval',
    FINALIZED_WITHOUT_OPTIMIZATION: 'status.finalized-without-optimization',
    SIGNED_WITHOUT_OPTIMIZATION: 'status.signed-without-optimization',
    REJECTED_BY_FINSTEIN: 'status.rejected-by-finstein',
    SCHEDULED_TO_TEMPORARY_INACTIVATION: 'status.scheduled-to-temporary-inactivation',
    SCHEDULED_TO_PERMANENT_INACTIVATION: 'status.scheduled-to-permanent-inactivation',
    TEMPORARY_DEACTIVATED: 'status.temporary-deactivated',
    DEACTIVATED: 'status.deactivated',
    FOR_EVERYONE: 'status.for-everyone'
};

export const STATUS_DESCRIPTIONS = {
    DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS:
        'status-description.data-export-pending-for-new-consultancy-process',
    ACTIVE: 'status-description.active',
    PARTIALLY_APPROVED: 'status-description.partially-approved',
    DATA_MISSING: 'status-description.data-missing',
    PENDENT: 'status-description.pendent',
    CONTRACT_EXPIRED: 'status-description.contract-expired',
    CONTRACT_CANCELED: 'status-description.contract-canceled',
    VERIFICATION_PENDING: 'status-description.verification-pending',
    DATA_EXPORT_PENDING: 'status-description.data-export-pending',
    CONSULTATION_APPROVAL_PENDING:
        'status-description.consultation-approval-pending',
    CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS:
        'status.consultation-approval-pending-for-new-consultancy-process',
    READY_FOR_CONSULTATION_PROCESS:
        'status-description.ready-for-consultation-process',
    IN_THE_CONSULTING_PROCESS: 'status-description.in-the-consulting-process',
    COP_COMPLETED_CONTRACT_SIGNING_PENDING:
        'status-description.cop-completed-contract-signing-pending',
    DATA_EXPORT_INCOMPLETE: 'status-description.data-export-incomplete',
    WAITING_CONTRACT_SIGNATURE: 'status-description.waiting-contract-signature',
    WAITING_APPROVAL: 'status-description.waiting-approval',
    WAITING_ACCEPT_PRIVACY_TERMS:
        'status-description.waiting-accept-privacy-terms',
    SALARY_INCREASE_PENDING: 'status-description.salary-increase-pending',
    WAITING_FOR_THE_GENERATION_OF_THE_CONTRACT:
        'status-description.waiting-for-the-generation-of-the-contract',
    PROBLEM_CONTRACT_GENERATION:
        'status-description.problem-contract-generation',
    ACTIVE_FINSTEIN_USER: 'status-description.active-finstein-user',
    SENT: 'status-description.sent',
    TEMPORARY_FAILURE: 'status-description.temporary-failure',
    RETRY: 'status-description.retry',
    DELIVERED: 'status-description.delivered',
    PERMANENT_FAILURE: 'status-description.permanent-failure',
    OPENED: 'status-description.opened',
    CLICKED: 'status-description.clicked',
    PENDING_OPTIN: 'status-description.pending-optin',
    FINISHED: 'status-description.finished',
    APPROVED: 'status-description.approved',
    NOT_ELIGIBLE_FOR_OPTIMIZATION: 'status-description.not-eligible-for-optimization',
    NOT_A_FINSTEIN_USER: 'status-description.not-a-finstein-user',
    UPLOADED: 'status-description.waiting-approval',
    FINALIZED_WITHOUT_OPTIMIZATION: 'status-description.finalized-without-optimization',
    SIGNED_WITHOUT_OPTIMIZATION: 'status-description.signed-without-optimization',
    REJECTED_BY_FINSTEIN: 'status-description.rejected-by-finstein',
    INCREASE_APPROVED_AND_PENDING_CONFIRMATION: 'status-description.increase-approved-and-pending-confirmation',
    SCHEDULED_TO_TEMPORARY_INACTIVATION: 'status-description.scheduled-to-temporary-inactivation',
    SCHEDULED_TO_PERMANENT_INACTIVATION: 'status-description.scheduled-to-permanent-inactivation',
    TEMPORARY_DEACTIVATED: 'status-description.temporary-deactivated',
    DEACTIVATED: 'status-description.deactivated',
    FOR_EVERYONE: 'status-description.for-everyone'
};


// Services of group one
export const SERVICE_MOBILE = 'mobile';
export const SERVICE_MOBILE_CONTRACT = 'mobile-contract';
export const SERVICE_EMPLOYEE_DISCOUNT = 'employee-discount';
export const SERVICE_RECREATIONAL_ACTIVITIES = 'recreational-activities';
export const SERVICE_RESTAURANT = 'restaurant';
export const SERVICE_CASH_REGISTER = 'cash-register';
export const SERVICE_HARDWARE = 'hardware';
export const SERVICE_RETIREMENT = 'retirement';

// Services of group two
export const SERVICE_COUPON = 'coupon';
export const SERVICE_JOB_TICKET = 'job-ticket';
export const SERVICE_COMMUTE = 'commute';
export const SERVICE_BIRTHDAY = 'birthday';
export const SERVICE_INTERNET = 'internet';
export const SERVICE_COMPANY_ADVERTISING = 'company-advertising';
export const SERVICE_CHILDCARE = 'childcare';

/**
 * Values for import optin
 */
export const WEEKLY_WORKING_HOURS = 40;
export const MAX_WEEKLY_WORKING_HOURS = 100;

/**
 * UTAB Values for Edlohn import
 */

export const HEALTH_INSURANSE_VALUES = {
    'IKK gesund plus (Ost)': '01000455',
    'IKK Brandenburg und Berlin': '01020803',
    'AOK Sachsen-Anhalt': '01029141',
    'IKK classic': '01049203',
    'AOK PLUS': '05174740',
    'IKK - Die Innovationskasse': '14228571',
    'Techniker Krankenkasse': '15027365',
    HEK: '15031806',
    'Mobil Krankenkasse': '15517302',
    'SECURVITA BKK': '15517482',
    'pronova BKK': '15872672',
    'AOK Bremen/Bremerhaven': '20012084',
    hkk: '20013461',
    'BKK firmus': '20156168',
    'BKK Salzgitter': '21203214',
    'BKK Public': '21488086',
    'BKK exklusiv': '22178373',
    'BKK Technoform': '23446040',
    BKK24: '23709856',
    'BKK EWE': '26515319',
    'TUI BKK': '29074470',
    KKH: '29137937',
    'energie-BKK': '29717581',
    'AOK Niedersachsen': '29720865',
    'BKK EUREGIO': '30168049',
    'BKK BPW Wiehl': '30980327',
    'Heimat Krankenkasse': '31209131',
    'Bertelsmann BKK': '31323584',
    'BKK Diakonie': '31323686',
    'BKK Miele': '31323700',
    'BKK DürkoppAdler': '31323799',
    'BKK GILDEMEISTER SEIDENSTICKER': '31323802',
    'AOK NordWest': '33526082',
    'Continentale Betriebskrankenkasse': '33865367',
    AKA: '33868451',
    'AOK Rheinland/Hamburg Die Gesundheitskasse': '34364249',
    'BKK Deutsche Bank AG': '34401277',
    'NOVITAS BKK': '35134022',
    'bkk melitta hmr': '36916935',
    'BKK VDN': '37416328',
    'VIACTIV Krankenkasse': '40180080',
    'BERGISCHE KRANKENKASSE': '42039708',
    BARMER: '42938966',
    'BKK Werra-Meissner': '44037562',
    'Merck BKK': '44377882',
    'Salus BKK': '44953697',
    'AOK Hessen': '45118687',
    'Ernst & Young BKK': '46939789',
    'BKK Wirtschaft & Finanzen': '46967693',
    'BKK Herkules': '47034953',
    'BKK B. Braun Aesculap': '47034975',
    'BKK PwC': '47307817',
    'KARL MAYER BKK': '48063096',
    'BKK Linde': '48698889',
    'DAK-Gesundheit': '48698890',
    'R+V Betriebskrankenkasse': '48944809',
    'BAHN-BKK': '49003443',
    'BKK PFAFF': '51588416',
    'AOK Rheinland-Pfalz/Saarland': '51605725',
    'BKK evm': '51980490',
    'Debeka BKK': '52156763',
    'BKK Pfalz': '52598579',
    'IKK Südwest': '55811201',
    'BKK Groz-Beckert': '60393261',
    'BKK Scheufelen': '61232758',
    'WMF BKK': '61232769',
    'Südzucker BKK': '62332660',
    'mhplus BKK West': '63494759',
    'BKK FREUDENBERG': '63922962',
    'BKK MTU': '65710574',
    'vivida bkk': '66458477',
    'BKK Schwarzwald-Baar-Heuberg': '66614249',
    'BKK Rieker.RICOSTA.Weisser': '66626976',
    'BKK Würth': '67161380',
    'AOK Baden-Württemberg': '67450665',
    'BKK MAHLE': '67572537',
    'BOSCH BKK': '67572593',
    'Mercedes-Benz BKK': '68216980',
    'BKK ZF & Partner': '69753266',
    'BKK VerbundPlus': '69785429',
    'BKK Akzo Nobel Bayern': '71579930',
    'BKK Textilgruppe Hof': '73170269',
    'Krones BKK': '74157435',
    'SKD BKK': '74773896',
    'Koenig & Bauer BKK': '75925585',
    'Audi BKK': '82889062',
    'BKK Faber-Castell & Partner': '86772584',
    'BMW BKK': '87271125',
    'AOK Bayern Die Gesundheitskasse': '87880235',
    SBK: '87954699',
    'BKK ProVita': '88571250',
    'AOK Nordost': '90235319',
    'BKK VBU': '92644250',
    'BIG direkt gesund': '97141402',
    'BKK Voralb': '97352653',
    'Knappschaft (allg. Verf. einschl. Minijobs)': '98000006',
    'Knappschaft (bes. knappschaftl. Meldeverf.': '98094032',
    'AOK Nordost - Die Gesundheitskasse Potsdam (Uml)': '90235319',
    'BARMER (vormals BARMER GEK) Wuppertal (Uml)': '42938966',
    'BKK Diakonie Bielefeld (Uml)': '31323686',
    'BKK Verkehrsbau Union Berlin (Uml)': '92644250',
    'DAK-Gesundheit Hamburg (Uml)': '48698890',
    'KKH Kaufmännische Krankenkasse Hannover (Uml)': '29137937',
    'mhplus Betriebskrankenkasse West Ludwigsburg (Uml)': '63494759',
    'Mobil Krankenkasse Hamburg (Uml)': '15517302',
    'SBK HV West München (Uml)': '87954699',
    'Techniker Krankenkasse -Rechtskreis West und Ost- Hamburg (Uml)': '15027365',
    'vivida bkk Villingen-Schwenningen (Uml)': '66458477'
};

export const MAX_VALUE_SERVICE_COUPON = 50;

/**
 * Values to validate file type
 */
export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
export const VIDEO_TYPES = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'];

export const EXPECTED_PENSION_IS_COMPENSATED = 1;
export const GUARANTEED_PENSION_IS_COMPENSATED = 2;
export const ACTIVE_MINIMUM_AMOUNT_TO_BE_USED_FOR_THE_BAV = 3;

export const ABSENCE_REGISTRATION_MAX_YEARS_FORWARD = 4;

export const EMPLOYEE_TEMPORARY_DEACTIVATION_REASONS = [
    {value: 'PARENTAL_LEAVE', label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.parental-leave'},
    {value: 'MATERNITY_PROTECTION', label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.maternity-protection'},
    {
        value: 'ILLNESS_FOR_MORE_THAN_6_WEEKS',
        label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.illness-for-more-than-6-weeks'
    },
    {value: 'SABBATICAL_YEAR', label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.sabbatical-year'},
    {value: 'PARTIAL_RETIREMENT', label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.partial-retirement'},
    {value: 'OTHER', label: 'employees.dialog.confirm-disable-employee.form-pause.reasons.other'},
];

export const SERVICES_NAMES = {
    [SERVICE_BIRTHDAY]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.birthday',
    [SERVICE_CASH_REGISTER]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.cash-register',
    [SERVICE_CHILDCARE]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.childcare',
    [SERVICE_COMMUTE]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.commute',
    [SERVICE_COMPANY_ADVERTISING]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.company-advertising',
    [SERVICE_COUPON]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.coupon',
    [SERVICE_EMPLOYEE_DISCOUNT]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.employee-discount',
    [SERVICE_HARDWARE]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.hardware',
    [SERVICE_INTERNET]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.internet',
    [SERVICE_JOB_TICKET]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.job-ticket',
    [SERVICE_MOBILE]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.mobile',
    [SERVICE_MOBILE_CONTRACT]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.mobile-contract',
    [SERVICE_RECREATIONAL_ACTIVITIES]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.recreational-activities',
    [SERVICE_RESTAURANT]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.restaurant',
    [SERVICE_RETIREMENT]:
        'employees.contracts.confirm-contract-inserted-into-payroll-system.benefits-services.retirement',
};