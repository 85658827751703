export const environment = {
    production: false,
    env: 'dev',
    cryptoSecretKey: '$2y$12$IU.ooOzWZh/oiPGSkO4PE.hIGeoOTlo3v6Z14MgdGbwfop9R7rggm',
    firebaseConfig: {
        apiKey: 'AIzaSyD4bvBq2gI8xAptwoJHZyOx8qXuWU7T1j0',
        authDomain: 'finstein-dev.firebaseapp.com',
        databaseURL: 'https://finstein-rt-dev.europe-west1.firebasedatabase.app/',
        projectId: 'finstein-dev',
        storageBucket: 'finstein-dev.appspot.com',
        messagingSenderId: '276464988372',
        appId: '1:276464988372:web:6d7c9a18c316e8298f0b41',
        measurementId: 'G-BC8MJRLMFS'
    },
    reCaptchaConfigs: {
        siteKey: '6LckAvobAAAAAH40SESd640GdklF3ilgY_Am9T28'
    },
    scheduleSupportAppointmentLink: 'https://meetings.hub.finstein.de/meetings',
    authExpirationTime: 600, // 10min
    authRecoveryTime: 30, // seconds
    websiteBaseURL: 'http://localhost:4200',
    portalBaseURL: 'https://finstein-dev.web.app'
};
